<script>
export default {
  async mounted() {
    const email = JSON.parse(localStorage.setPersonaAct).correo;
    let userLogged = await this.$store.dispatch("get", {
      path: `Persona/GetPersonaRecuperar/${email}`,
    });
    this.$store.commit("updateFormJobSearch", {
      key: "CreadorId",
      id: userLogged.id,
    });
    this.$store.commit("updateFormJobSearch", {
      key: "EmpresaId",
      id: userLogged.empresaId,
    });
    this.findSucursal(userLogged.empresaId);
  },
  data() {
    return {
      sucursales: [],
      departamentos: [],
      areas: [],
      cargos: [],
      isNewRegistro: false,
    };
  },
  methods: {
    async findSucursal(empresaId) {
      let sucursales = await this.$store.dispatch("get", {
        path: `Sucursal/ListSucursalEmpresa/${empresaId}`,
      });
      let newData = sucursales.map((sucursal) => {
        return {
          value: sucursal.id,
          text: sucursal.descripcion,
        };
      });
      this.sucursales = newData;
    },
    async findDepartamento(sucursalId) {
      let departamentos = await this.$store.dispatch("get", {
        path: `Departamento/ListDepartamentoSucursal/${sucursalId}`,
      });
      let newData = departamentos.map((departamento) => {
        return {
          value: departamento.id,
          text: departamento.descripcion,
        };
      });
      this.departamentos = newData;
    },
    async findArea(departamentoId) {
      let areas = await this.$store.dispatch("get", {
        path: `Area/ListAreaDepartamento/${departamentoId}`,
      });
      let newData = areas.map((area) => {
        return {
          value: area.id,
          text: area.descripcion,
        };
      });
      this.areas = newData;
    },
    async findCargo(areaId) {
      let cargos = await this.$store.dispatch("get", {
        path: `Cargo/ListCargoArea/${areaId}`,
      });
      let newData = cargos.map((cargo) => {
        return {
          value: cargo.id,
          text: cargo.descripcion,
        };
      });
      this.cargos = newData;
    },
    async postNewIdOfPeligros() {
      let model = this.$store.state.peligrosYRiesgos.formJobSearch;
      this.$store
        .dispatch("post", { path: `PeligroIdentificadorPeligro`, data: model })
        .then(() => {
          this.isNewRegistro = false;
          this.$store.dispatch("setPeligroIsDisabled", false);
        });
    },
  },
  computed: {
    EmpresaId() {
      return this.$store.state.peligrosYRiesgos.EmpresaId;
    },
    SucursalId: {
      get() {
        return this.$store.state.peligrosYRiesgos.SucursalId;
      },
      set(value) {
        if (this.$store.getters.getItemJobSearch("SucursalId") != value) {
          this.findDepartamento(value);
        }
        this.$store.commit("updateFormJobSearch", {
          key: "SucursalId",
          id: value,
        });
      },
    },

    DepartamentoId: {
      get() {
        return this.$store.state.peligrosYRiesgos.DepartamentoId;
      },
      set(value) {
        if (this.$store.getters.getItemJobSearch("DepartamentoId") != value) {
          this.findArea(value);
        }
        this.$store.commit("updateFormJobSearch", {
          key: "DepartamentoId",
          id: value,
        });
      },
    },

    AreaId: {
      get() {
        return this.$store.state.peligrosYRiesgos.AreaId;
      },
      set(value) {
        if (this.$store.getters.getItemJobSearch("AreaId") != value) {
          this.findCargo(value);
        }
        this.$store.commit("updateFormJobSearch", { key: "AreaId", id: value });
      },
    },

    CargoId: {
      get() {
        return this.$store.state.peligrosYRiesgos.CargoId;
      },
      set(value) {
        this.$store.commit("updateFormJobSearch", {
          key: "CargoId",
          id: value,
        });
        if (this.$store.getters.getItemJobSearch("CargoId") != null) {
          this.$store.dispatch("findRegistro").then((registro) => {
            if (registro != null) {
              this.isNewRegistro = false;

              // JobSearch
              this.$store.commit("updateFormJobSearch", {
                key: "NumeroHorasLaboralesAlDia",
                id: registro.numeroHorasLaboralesAlDia,
              });
              this.$store.commit("updateFormJobSearch", {
                key: "NumeroTrabajadores",
                id: registro.numeroTrabajadores,
              });
              this.$store.commit("resetPeligroMopdels");
              this.$store.dispatch("initQuerys");
              this.$store.dispatch("setPeligroIsDisabled", false);

              // console.log(registro); // ! borrar
              //traer toda la informacion relacionada a peligro
            } else {
              this.$store.dispatch("setPeligroIsDisabled", true);
              this.isNewRegistro = true;
            }
          });
        }
      },
    },

    NumeroHorasLaboralesAlDia: {
      get() {
        return this.$store.state.peligrosYRiesgos.NumeroHorasLaboralesAlDia;
      },
      set(value) {
        if (value > 24) {
          value = 24;
        }
        this.$store.commit("updateFormJobSearch", {
          key: "NumeroHorasLaboralesAlDia",
          id: value,
        });
      },
    },

    NumeroTrabajadores: {
      get() {
        return this.$store.state.peligrosYRiesgos.NumeroTrabajadores;
      },
      set(value) {
        value = Number(value);
        this.$store.commit("updateFormJobSearch", {
          key: "NumeroTrabajadores",
          id: value,
        });
      },
    },

    formJobSearch() {
      return this.$store.state.peligrosYRiesgos.formJobSearch;
    },
  },
};
</script>

<template>
  <b-form @submit.prevent="postNewIdOfPeligros">
    <!-- <button @click.prevent="postNewIdOfPeligros">Prueba</button> -->
    <h4 class="text-white mb-3"><b>Identificación del cargo</b></h4>
    <div class="grid-container">

      <!-- sucursal -->
      <b-form-group label-for="sucursal" label="Sucursal">
        <b-form-select id="sucursal" v-model="SucursalId" :options="sucursales" required></b-form-select>
      </b-form-group>
      <!-- departamento -->
      <b-form-group label="Departamento" label-for="departamento">
        <b-form-select id="departamento" v-model="DepartamentoId" :options="departamentos" required></b-form-select>
      </b-form-group>
      <!-- area -->
      <b-form-group label="Área" label-for="area">
        <b-form-select id="area" v-model="AreaId" :options="areas" required></b-form-select>
      </b-form-group>

      <!-- cargo -->
      <b-form-group label="Cargo" label-for="cargo">
        <b-form-select id="cargo" v-model="CargoId" :options="cargos" required></b-form-select>
      </b-form-group>

      <!-- numero de trabajadores -->
      <b-form-group label="Número de trabajadores" label-for="area-numeroTrabajadores">
        <b-form-input v-model="formJobSearch.NumeroTrabajadores" id="area-numeroTrabajadores" type="number" min="1"
          required></b-form-input>
      </b-form-group>

      <!-- horas laborales -->
      <b-form-group label="Horas laborales al día" label-for="area-horasLaborales">
        <b-form-input v-model="formJobSearch.NumeroHorasLaboralesAlDia" id="area-horasLaborales" type="number" min="1"
          required></b-form-input>
      </b-form-group>
    </div>
    <b-button v-if="isNewRegistro" type="submit" variant="outline-info">Crear registro</b-button>
  </b-form>
</template>

<style>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
</style>
